import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      <FooterColumn>
        
          <Logo>Alpha Algo</Logo>
          
      </FooterColumn>
      
      <FooterColumn>
        <span>&nbsp;</span>
        <ul>
          <li><FormSubtitleLink href="/terms-of-use.html" target="_blank" rel="noopener">Terms of Use</FormSubtitleLink></li>
          <li><FormSubtitleLink href="/disclaimer.html" target="_blank" rel="noopener">Disclaimer</FormSubtitleLink></li>
          {/* <li>Blog</li> */}
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>&nbsp;</span>
        <ul>
        <li><FormSubtitleLink href="/privacy-policy.html" target="_blank" rel="noopener">Privacy Policy</FormSubtitleLink></li>
          {/* <li>Careers</li> */}
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>&nbsp;</span>
        <ul>
        <li><FormSubtitleLink href="/about-us.html" target="_blank" rel="noopener">About Us</FormSubtitleLink></li>
          <li><FormSubtitleLink href="mailto:support@alphaalgo.in" target="_blank" rel="noopener">Contact Us (support@alphaalgo.in)</FormSubtitleLink></li>
        </ul>
      </FooterColumn>
    </FooterColumnContainer>
    <BrandContainer>
    <FormSubtitle>Our service includes products that are traded on margin and carry a risk of losses in excess of your deposited funds. The products may not be suitable for all investors. Please ensure that you fully understand the risks involved.<br/>
                            <br/>
                            The purpose of the report/presentation is to provide essential information about the services in a manner to assist and enable the investors in making informed decisions for engaging on our platform. The presentation/report is for informational purposes only and should not be regarded as an offer to sell or a solicitation of an offer to buy the securities or other investments mentioned in it or follow an investment advice. We do not represent that any information, including any third party information, is accurate or complete and it should not be relied upon without proper investigation on the part of the investor/s. Investments in securities/equity related instruments are subject to market risk. These risks could be security specific or market specific and arising from company, industry, political, economic (both domestic and global), etc, factors.<br/>
                            <br/>
                            Past performance of advice/strategy/model does not indicate the future performance of any current or future strategy/model or advise by Alpha Algo (Alpha Algo Technologies Private Limited) and actual returns may differ significantly from that depicted herein due to various factors including but not limited to impact costs, expense charged, timing of entry/exit, timing of additional flows/redemptions, individual client mandates, specific portfolio construction characteristics etc. There is no assurance or guarantee that the objectives of any strategy/model or advice provided by Alpha Algo (Alpha Algo Technologies Private Limited) will be achieved. Alpha Algo (Alpha Algo Technologies Private Limited) or any of its partner/s or principal officer/employees do not assure/give guarantee for any return on the investment in strategies/models/advice given to the Investor. The value of investment can go up/down depending on factors & forces affecting securities markets. Alpha Algo (Alpha Algo Technologies Private Limited) or its associates are not liable or responsible for any loss or shortfall arising from operations and affected by the market condition. Securities investment is subject to market risks and Alpha Algo (Alpha Algo Technologies Private Limited) makes no representation, assurance or guarantee that objective of any of the models/strategies/advise on the platform or communicated will be achieved in future.The necessary information about the Investment required by an investor before investing is disclosed in the relevant agreement. The investor should carefully read all terms and conditions of the agreement before subscribing to services and shall not make Alpha Algo (Alpha Algo Technologies Private Limited) and/or its associates/employees liable for any risks/losses pertaining to any strategies/models/advice launched/provided by Alpha Algo (Alpha Algo Technologies Private Limited) from time to time.</FormSubtitle>
    </BrandContainer>

  </FooterWrapper>
)

const FormSubtitle = styled.span`
  ${props => props.theme.font_size.xxsmall}
`

const FormSubtitleLink = styled(Link)`
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
  // border-bottom: 1px solid ${props => props.theme.color.secondary};
`

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const Logo = styled.div`
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 24px;
  display: flex;
  align-items: flex-end;
  border-top: 1px solid ${props => props.theme.color.secondary};
  @media (max-width: ${props => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

export default Footer
